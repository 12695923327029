/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
enum EmployeesDashboardView {
	EMPLOYEES_DETAILS = "employees_details",
	ORIGINAL_VIEW = "original_view",
}

const EMPLOYEES_DASHBOARD_VIEW_DEFAULT: EmployeesDashboardView = EmployeesDashboardView.EMPLOYEES_DETAILS;

const EMPLOYEES_DASHBOARD_VIEW_LOCAL_STORAGE_KEY = "employees-dashboard-view-1";

const EMPLOYEES_DASHBOARD_VIEW_LOCAL_STORAGE_KEY_OLD = "employees-dashboard-view";

export {
	EmployeesDashboardView,
	EMPLOYEES_DASHBOARD_VIEW_DEFAULT,
	EMPLOYEES_DASHBOARD_VIEW_LOCAL_STORAGE_KEY,
	EMPLOYEES_DASHBOARD_VIEW_LOCAL_STORAGE_KEY_OLD,
};
