/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	PROJECT_VIEW_MODE,
} from "constants/projects";

const DEFAULT_PROJECT_DETAILS_VIEW_MODE = PROJECT_VIEW_MODE.EMPLOYEE_DAILY;

const PROJECT_DETAILS_VIEW_LOCAL_STORAGE_KEY = "project-details-view";

export {
	DEFAULT_PROJECT_DETAILS_VIEW_MODE,
	PROJECT_DETAILS_VIEW_LOCAL_STORAGE_KEY,
};
