/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	generateClonePackagePageUrl,
} from "pages/utils/generate-clone-package-page-url";
import {
	generateEditPackagePageUrl,
} from "pages/utils/generate-edit-package-page-url";
import {
	generateEmployeesPageUrl,
} from "pages/utils/generate-employees-page-url";
import {
	generateNewPackagePageUrl,
} from "pages/utils/generate-new-package-page-url";
import {
	generateOvertimeDashboardPageUrl,
} from "pages/utils/generate-overtime-dashboard-page-url";
import {
	generateOvertimeRequestDetailsPageUrl,
} from "pages/utils/generate-overtime-request-details-page-url";
import {
	generatePackagesIssueMappingPageUrl,
} from "pages/utils/generate-packages-issue-mapping-page-url";
import {
	generatePackagesPageUrl,
} from "pages/utils/generate-packages-page-url";
import {
	generateProjectPageUrl,
} from "pages/utils/generate-project-page-url";
import {
	generateProjectTMSLogPageUrl,
} from "pages/utils/generate-project-tms-log-page-url";
import {
	generateProjectsPageUrl,
} from "pages/utils/generate-projects-page-url";
import {
	generateTimeJournalPageUrl,
} from "pages/utils/generate-time-journal-page-url";

import {
	EmployeesDashboardView,
} from "./employees-page";

const FROM_DATE_PATH_PATTERN = ":fromDate([0-9]{4}-[0-9]{2}-[0-9]{2})";
const TO_DATE_PATH_PATTERN = ":toDate([0-9]{4}-[0-9]{2}-[0-9]{2})";
const EMPLOYEE_ID_PATH_PATTERN = ":userId([0-9]+)";
const OVERTIME_REQUEST_ID_PATH_PATTERN = ":overtimeRequestId([0-9]+)";
const PROJECT_ID_PATH_PATTERN = ":projectId([0-9]+)";
const LOG_TMS_PROJECT_ID_PATH_PATTERN = ":projectId([0-9]+|npa)";
const PACKAGE_ID_PATH_PATTERN = ":packageId([0-9]+)";

const TIME_JOURNAL_ROUTE_PATH = generateTimeJournalPageUrl({
	employeeId: EMPLOYEE_ID_PATH_PATTERN,
	periodStartDate: FROM_DATE_PATH_PATTERN,
	periodEndDate: TO_DATE_PATH_PATTERN,
});

const OVERTIME_DASHBOARD_ROUTE_PATH = generateOvertimeDashboardPageUrl({
	periodStartDate: FROM_DATE_PATH_PATTERN,
	periodEndDate: TO_DATE_PATH_PATTERN,
});

const OVERTIME_DETAILS_ROUTE_PATH = generateOvertimeRequestDetailsPageUrl({
	overtimeRequestId: OVERTIME_REQUEST_ID_PATH_PATTERN,
});

const PROJECTS_DASHBOARD_ROUTE_PATH = generateProjectsPageUrl({
	periodStartDate: FROM_DATE_PATH_PATTERN,
	periodEndDate: TO_DATE_PATH_PATTERN,
});

const PROJECT_DETAILS_VIEWS = Object.values(PROJECT_VIEW_MODE).join("|");

const PROJECT_DETAILS_OLD_VIEWS_ROUTE_PATH = generateProjectPageUrl({
	projectId: PROJECT_ID_PATH_PATTERN,
	periodStartDate: FROM_DATE_PATH_PATTERN,
	periodEndDate: TO_DATE_PATH_PATTERN,
	viewMode: `:viewMode(${PROJECT_DETAILS_VIEWS as PROJECT_VIEW_MODE})`,
});

const PROJECT_DETAILS_NEW_VIEW_ROUTE_PATH = generateProjectPageUrl({
	projectId: PROJECT_ID_PATH_PATTERN,
	periodStartDate: FROM_DATE_PATH_PATTERN,
	periodEndDate: TO_DATE_PATH_PATTERN,
	viewMode: `:viewMode(${PROJECT_VIEW_MODE.POSITION_BREAKDOWN})`,
});

const EMPLOYEES_DASHBOARD_OLD_VIEW_ROUTE_PATH = generateEmployeesPageUrl({
	periodStartDate: FROM_DATE_PATH_PATTERN,
	periodEndDate: TO_DATE_PATH_PATTERN,
});

const EMPLOYEES_DASHBOARD_NEW_VIEW_ROUTE_PATH = generateEmployeesPageUrl({
	periodStartDate: FROM_DATE_PATH_PATTERN,
	periodEndDate: TO_DATE_PATH_PATTERN,
	view: `:view(${EmployeesDashboardView.EMPLOYEES_DETAILS})`,
});

const PACKAGES_DASHBOARD_ROUTE_PATH = generatePackagesPageUrl({
	projectId: PROJECT_ID_PATH_PATTERN,
	periodStartDate: FROM_DATE_PATH_PATTERN,
	periodEndDate: TO_DATE_PATH_PATTERN,
});

const CLONE_PACKAGE_ROUTE_PATH = generateClonePackagePageUrl({
	projectId: PROJECT_ID_PATH_PATTERN,
	packageId: PACKAGE_ID_PATH_PATTERN,
});

const EDIT_PACKAGE_ROUTE_PATH = generateEditPackagePageUrl({
	projectId: PROJECT_ID_PATH_PATTERN,
	packageId: PACKAGE_ID_PATH_PATTERN,
});

const NEW_PACKAGE_ROUTE_PATH = generateNewPackagePageUrl({
	projectId: PROJECT_ID_PATH_PATTERN,
});

const ISSUE_MAPPING_ROUTE_PATH = generatePackagesIssueMappingPageUrl({
	projectId: PROJECT_ID_PATH_PATTERN,
});

const TMS_LOG_ROUTE_PATH = generateProjectTMSLogPageUrl({
	projectId: LOG_TMS_PROJECT_ID_PATH_PATTERN,
});

export {
	FROM_DATE_PATH_PATTERN,
	TO_DATE_PATH_PATTERN,
	EMPLOYEE_ID_PATH_PATTERN,
	PROJECT_ID_PATH_PATTERN,

	TIME_JOURNAL_ROUTE_PATH,
	OVERTIME_DASHBOARD_ROUTE_PATH,
	OVERTIME_DETAILS_ROUTE_PATH,
	PROJECTS_DASHBOARD_ROUTE_PATH,
	PROJECT_DETAILS_OLD_VIEWS_ROUTE_PATH,
	PROJECT_DETAILS_NEW_VIEW_ROUTE_PATH,
	EMPLOYEES_DASHBOARD_OLD_VIEW_ROUTE_PATH,
	EMPLOYEES_DASHBOARD_NEW_VIEW_ROUTE_PATH,
	PACKAGES_DASHBOARD_ROUTE_PATH,
	CLONE_PACKAGE_ROUTE_PATH,
	EDIT_PACKAGE_ROUTE_PATH,
	NEW_PACKAGE_ROUTE_PATH,
	ISSUE_MAPPING_ROUTE_PATH,
	TMS_LOG_ROUTE_PATH,
};
