/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
const TIME_JOURNAL_BASE_URL = "/journal";

const OVERTIME_BASE_URL = "/overtime";

const PROJECTS_BASE_URL = "/projects";

const EMPLOYEES_BASE_URL = "/employees";

const PACKAGES_BASE_URL = "/packages";

const TMS_LOG_BASE_URL = "/logtms";

/*
	"npa" is a special project ID.
	See also `LOG_TMS_PROJECT_ID_PATH_PATTERN` constant.
*/
const NPA_TMS_LOG_BASE_URL = `${TMS_LOG_BASE_URL}/npa`;

export {
	TIME_JOURNAL_BASE_URL,
	OVERTIME_BASE_URL,
	PROJECTS_BASE_URL,
	EMPLOYEES_BASE_URL,
	PACKAGES_BASE_URL,
	TMS_LOG_BASE_URL,
	NPA_TMS_LOG_BASE_URL,
};
